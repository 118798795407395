// import Hotjar from "@hotjar/browser";

import { isBrowser } from "@fwa/src/utils/browserUtils";
// import { checkIfDev } from "@fwa/src/utils/envUtils";

// TODO currently hotjar loading is backed into our GTM container code,
// we want to unpick this after FWA deploy and use this init function instead
// this is so we can control hotjar without having to go though the GTM team
export const initHotjar = () => {
  // if (!isBrowser || checkIfDev() ) return;
  // const siteId = 587808;
  // const hotjarVersion = 6;
  // Hotjar.init(siteId, hotjarVersion);
};

export const hotjarSetUserAttribute = (userAttributes: {
  is_logged_in: boolean;
  fundraiser_id: string | null;
}) => {
  // urgh without the lib coming from GTM this is a stab in the dark
  if (!isBrowser || !window.hj || typeof window.hj !== "function") return;
  try {
    window?.hj("identify", null, userAttributes);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // console.error("hotjarSetUserAttribute error", e);
  }
};

export default initHotjar;
