import { isBrowser } from "@fwa/src/utils/browserUtils";

/* eslint-disable  @typescript-eslint/ban-ts-comment */
export const canTrackPerformance = () => {
  if (!isBrowser) return false;
  // @ts-ignore assuming OnetrustActiveGroups on window object
  if (!window.OnetrustActiveGroups) return false;
  // @ts-ignore assuming OnetrustActiveGroups on window object
  const activeGroups = window.OnetrustActiveGroups as string;
  return activeGroups.split(",").includes("per");
};
/* eslint-enable */

export const onPerformanceCookieEnabled = (callbacks: (() => void)[]) => {
  if (!isBrowser) return;
  if (canTrackPerformance()) {
    callbacks.forEach((callback) => callback());
  } else {
    window.addEventListener("consent.onetrust", () => {
      if (canTrackPerformance()) {
        callbacks.forEach((callback) => callback());
      }
    });
  }
};
