export const buildQuery = (
  obj: Record<string, string | number | boolean | null> | null,
) => {
  if (!obj || Object.keys(obj).length === 0) return "";
  const result = Object.keys(obj)
    .map((key) => {
      if (obj[key]) {
        const value: string | number | boolean = obj[key] || "";
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
      return null;
    })
    .join("&");
  return `?${result}`;
};

export const getUrlSearchParams = (urlString: string): URLSearchParams => {
  const url = new URL(urlString);
  return url.searchParams;
};

export const getPathFromUrl = (urlString: string): string => {
  try {
    const url = new URL(urlString);
    return url.pathname;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    //  doesn't throw when we have dodgy of incomplete data with integration
    return urlString;
  }
};

/**
 * Simple utility function to return the value of a specific query parameter. Returns null if the value is not set, or
 * if the current URL is not set (i.e. for server side rendering).
 *
 * @param searchString
 * @param queryParameter
 * @return string|null
 */
export const getQueryValueFromUrlSearchString = (
  searchString: string,
  queryParameter: string,
): string | null => new URLSearchParams(searchString).get(queryParameter);

// Query might be string, string array or undefined, when we want to figure out slug for a url always want cast it as a string.
export const queryAsString = (query?: string | string[] | null): string => {
  if (typeof query === "undefined" || !query) {
    return "";
  }
  return Array.isArray(query) ? query.join("/") : query;
};

// Query might be string, string array or undefined, when we want to figure out slug for a url always want cast it as a string.
export const queryAsArray = (query?: string | string[] | null): string[] => {
  if (typeof query === "undefined" || !query) {
    return [];
  }
  return Array.isArray(query) ? query : [query];
};

export const removeURLParam = (urlString: string, param: string): string => {
  const url = new URL(urlString);
  url.searchParams.delete(param);
  return url.toString();
};
